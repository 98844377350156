import React from 'react';
import PropTypes from 'prop-types';

import styles from './h2-example.module.css';
import getAnchor from './../../../utils/anchor';

const H2Example = ({ children }) => {
  const anchor = getAnchor(children);
  const link = `#${anchor}`;
  return (
    <div className="container">
      <div className={styles.h2Container}>
        <h2 id={anchor} className={styles.h2}>
          <a href={link} className={styles.anchorLink} aria-hidden="true">
            §
          </a>
          {children}
        </h2>
      </div>
    </div>
  );
};

H2Example.propTypes = {
  children: PropTypes.any,
};

export default H2Example;
