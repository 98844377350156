import React from 'react';
import PropTypes from 'prop-types';
import styles from '../RadialGauge.module.css';

const TRACK_SIZE_DEGREES = 270;
const TRACK_WIDTH_PX = 7;
const VIEW_BOX_SIZE_PX = 100;

export default function RadialGaugeStep1Track(props) {
  const { step, trackSizeDegrees } = props;
  const viewBox = `0 0 ${VIEW_BOX_SIZE_PX} ${VIEW_BOX_SIZE_PX}`;
  const radius = VIEW_BOX_SIZE_PX / 2 - TRACK_WIDTH_PX / 2;

  const circumference = 2 * Math.PI * radius;

  // Track
  let dasharray, trackFillPercentage, trackDashoffset;

  if (step === 1) {
    dasharray = 'none';
    trackDashoffset = 0;
  } else {
    dasharray = circumference;
    trackFillPercentage = trackSizeDegrees / 360;
    trackDashoffset = circumference * (1 - trackFillPercentage);
  }

  const cxy = VIEW_BOX_SIZE_PX * 0.5;
  const trackTransform = `rotate(${
    -(trackSizeDegrees / 2) - 90
  }, ${cxy}, ${cxy})`;

  return (
    <div className={styles.gaugeContainer}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
        <circle
          fill="none"
          strokeLinecap={step >= 3 ? 'round' : 'butt'}
          cx="50%"
          cy="50%"
          r={radius}
          stroke="white"
          strokeDasharray={dasharray}
          strokeDashoffset={trackDashoffset}
          strokeWidth={TRACK_WIDTH_PX}
          transform={step >= 3 ? trackTransform : undefined}
        />
      </svg>
    </div>
  );
}

RadialGaugeStep1Track.defaultProps = {
  step: 1,
  trackSizeDegrees: TRACK_SIZE_DEGREES,
};

RadialGaugeStep1Track.propTypes = {
  step: PropTypes.number,
  trackSizeDegrees: PropTypes.number,
};
