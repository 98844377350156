import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button/button';
import Caption from '../../Caption/caption';

const SimpleTitleChanger = () => {
  const initialCount = 6;
  const [isRunning, setIsRunning] = useState(false);
  const [count, setCountdown] = useState(initialCount);
  const [title, setTitle] = useState('');
  const [originalTitle, setOriginalTitle] = useState('');

  useEffect(() => {
    if (!originalTitle) {
      setOriginalTitle('Effective titles and more | Tomek Dev');
    }

    if (title) {
      document.title = title;

      if (title !== originalTitle) {
        setCountdown(count - 1);
        setIsRunning(true);
        const timer = setTimeout(() => {
          setTitle(originalTitle);
          setIsRunning(false);
        }, 5000);

        return () => clearTimeout(timer);
      }
    }
  }, [title]);

  useEffect(() => {
    if (count === 0) {
      setCountdown(initialCount);
    } else if (count !== initialCount) {
      const interval = setInterval(() => {
        setCountdown(count - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [count]);

  return (
    <div className="container ta-c">
      <Button
        type="button"
        disabled={isRunning}
        onClick={() => {
          setTitle('A new title | Tomek Dev');
        }}
      >
        {isRunning ? 'Check the title!' : 'Click here to try it!'}
      </Button>

      <Caption>
        {isRunning
          ? `(restoring title in ${count}s...)`
          : `(title will be restored after 5s)`}
      </Caption>
    </div>
  );
};

SimpleTitleChanger.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
};

export default SimpleTitleChanger;
