import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './resizable-area.module.css';

export default function ResizableArea({ children }) {
  const divRef = useRef(null);

  useEffect(() => {
    const div = divRef.current;

    let debounceTimeout = null;
    const resizeObserver = new ResizeObserver(() => {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => {
        const width = div.offsetWidth;
        div.style.height = width + 'px';
      }, 100);
    });

    resizeObserver.observe(div);

    return () => {
      resizeObserver.unobserve(div);
    };
  }, []);

  return (
    <div className={styles.resizableArea} ref={divRef}>
      {children}
    </div>
  );
}

ResizableArea.propTypes = {
  children: PropTypes.node,
};
