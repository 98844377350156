import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from '../../Input/input';

import styles from './highlight-text.module.css';

class HighlightText extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
    };

    this.search = this.search.bind(this);
  }

  search(event) {
    this.setState({
      searchText: event.target.value,
    });
  }

  _getText(text, searchText) {
    if (searchText) {
      return this._getTextWithHighlights(text, searchText);
    } else {
      return text;
    }
  }

  _getTextWithHighlights(text, searchText) {
    const regex = new RegExp(searchText, 'gi');
    let newText;

    if (this.props.smartReplacement) {
      newText = text.replace(
        regex,
        `<mark class=${styles.highlight}>$&</mark>`
      );
    } else {
      newText = text.replace(
        regex,
        `<mark class=${styles.highlight}>${searchText}</mark>`
      );
    }

    return <span dangerouslySetInnerHTML={{ __html: newText }} />;
  }

  render() {
    const { text, typeExample } = this.props;
    const { searchText } = this.state;
    const textToShow = this._getText(text, searchText);
    const placeholder = `Type "${typeExample}" for example`;

    return (
      <div className="container">
        <div className={styles.searchContainer}>
          <Input
            label="Search within quoted text"
            placeholder={placeholder}
            type="search"
            name="search"
            autoComplete="off"
            onChange={this.search}
            value={searchText}
          />
        </div>

        <figure>
          <blockquote
            className="blockquote"
            cite="https://en.wikipedia.org/wiki/JavaScript"
          >
            {textToShow}
          </blockquote>
          <figcaption>
            ~ from <cite>Wikipedia</cite>
          </figcaption>
        </figure>
      </div>
    );
  }
}

HighlightText.defaultProps = {
  smartReplacement: true,
  typeExample: 'web',
};

HighlightText.propTypes = {
  smartReplacement: PropTypes.bool,
  text: PropTypes.string,
  typeExample: PropTypes.string,
};

export default HighlightText;
