import React, { Component } from 'react';

import Textarea from '../../Textarea/textarea';

import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';

import styles from './autosave-rich-editor.module.css';

class AutosaveRichEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lastText: '',
      text: '',
      saveStates: [],
      saveThrottledStates: [],
      saveDebouncedStates: [],
      fakeApiTimeoutHandler: null,
      fakeApiTimeoutHandler2: null,
      fakeApiTimeoutHandler3: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
    this.saveDebounced = this.saveDebounced.bind(this);
    this.saveThrottled = this.saveThrottled.bind(this);
    this.setHistoryBack = this.setHistoryBack.bind(this);
    this.setHistoryState = this.setHistoryState.bind(this);

    this.handleSaveDebounced = debounce(this.saveDebounced, 2000);
    this.handleSaveThrottled = throttle(this.saveThrottled, 2000);
  }

  handleChange(event) {
    this.setState({
      lastText: event.target.value,
      text: event.target.value,
    });
    this.save();
  }

  save() {
    const handler = this._fakeApiCall(
      'isSaving',
      this.state.fakeApiTimeoutHandler
    );
    const saveStates = [...this.state.saveStates, this.state.text];
    this.setState({
      fakeApiTimeoutHandler: handler,
      saveStates,
    });

    this.handleSaveDebounced();
    this.handleSaveThrottled();
  }

  saveDebounced() {
    const handler = this._fakeApiCall(
      'isDebouncedLoading',
      this.state.fakeApiTimeoutHandler2
    );
    const saveDebouncedStates = [
      ...this.state.saveDebouncedStates,
      this.state.text,
    ];
    this.setState({
      fakeApiTimeoutHandler2: handler,
      saveDebouncedStates,
    });
  }

  saveThrottled() {
    const handler = this._fakeApiCall(
      'isThrottledLoading',
      this.state.fakeApiTimeoutHandler3
    );
    const saveThrottledStates = [
      ...this.state.saveThrottledStates,
      this.state.text,
    ];
    this.setState({
      fakeApiTimeoutHandler3: handler,
      saveThrottledStates,
    });
  }

  setHistoryState(text) {
    this.setState({ text });
  }

  setHistoryBack() {
    this.setState({ text: this.state.lastText });
  }

  _fakeApiCall(loadingName, handler) {
    this.setState({ [loadingName]: true });

    if (handler) {
      clearTimeout(handler);
    }

    return setTimeout(() => this.setState({ [loadingName]: false }), 500);
  }

  _generateDots(arr) {
    const output = [];
    for (let i = 0; i < arr.length; i++) {
      output.push(
        <span
          key={i}
          className={styles.countCircle}
          onMouseEnter={() => this.setHistoryState(arr[i])}
          onMouseLeave={this.setHistoryBack}
        >
          <span className={styles.countCircleInner}></span>
        </span>
      );
    }
    return output;
  }

  render() {
    const {
      saveStates,
      saveThrottledStates,
      saveDebouncedStates,
      text,
    } = this.state;

    return (
      <div className="container">
        <div className={styles.editorContainer}>
          <Textarea
            label="Bio"
            placeholder="Type your bio..."
            name="rich-editor"
            autoComplete="off"
            onChange={this.handleChange}
            rows="4"
            value={text}
          />
        </div>

        <div className={styles.countBoxes}>
          <div className={styles.countBox}>
            <div className={styles.countBoxTitle}>No timing function</div>
            <div className={styles.savedStates}>Saved states:</div>
            <div>{this._generateDots(saveStates)}</div>
          </div>
          <div className={styles.countBox}>
            <div className={styles.countBoxTitle}>Throttled</div>
            <div className={styles.savedStates}>Saved states:</div>
            <div>{this._generateDots(saveThrottledStates)}</div>
          </div>
          <div className={styles.countBox}>
            <div className={styles.countBoxTitle}>Debounced</div>
            <div className={styles.savedStates}>Saved states:</div>
            <div>{this._generateDots(saveDebouncedStates)}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default AutosaveRichEditor;
