import React from 'react';
import PropTypes from 'prop-types';

import styles from './date.module.css';

const PostDate = ({ date, dateMachine }) => (
  <div className="container">
    <div className={styles.date}>
      <span>
        <time dateTime={dateMachine}>{date}</time>
      </span>
    </div>
  </div>
);

PostDate.propTypes = {
  date: PropTypes.string.isRequired,
  dateMachine: PropTypes.string,
};

export default PostDate;
