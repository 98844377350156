import { degToRad, distanceBetweenPoints } from './math-utils';

export function calculateSetterPosition(radius, size, value) {
  /*
    Setter element is a circle with outer-circle on the track that points the actual value. It is also used to
    set the value while moving / dragging.

    To properly calculate the position of the setter we use simple equations (angles in radians):
      x = - radius * sin(angle)
      y =   radius * cos(angle)

    Equations below are a bit more complicated because `size` property determines
    how many "angles" is our 100% (full circle = 360°). We need to take a proportion and adjust the angle
    with half of the part of the circle that "remains".
   */

  return {
    x: -radius * Math.sin(degToRad((value / 100) * size + (360 - size) / 2)),
    y: radius * Math.cos(degToRad((value / 100) * size + (360 - size) / 2)),
  };
}

export function calculateStepsCoords(radius, size) {
  const stepsCoords = [];
  for (let i = 0; i <= 100; i++) {
    const coords = calculateSetterPosition(radius, size, i);

    stepsCoords.push({
      value: i,
      x: coords.x,
      y: coords.y,
    });
  }
  return stepsCoords;
}

export function findClosestStep(stepsCoords, x, y) {
  let min = distanceBetweenPoints({ x, y }, stepsCoords[0]);
  let minStep = stepsCoords[0];

  stepsCoords.forEach((step) => {
    const distance = distanceBetweenPoints({ x, y }, step);

    if (min > distance) {
      min = distance;
      minStep = step;
    }
  });

  return minStep;
}
