import Date from "../../../../src/components/Post/date";
import HeroPost from "../../../../src/components/Hero/hero-post";
import TwitterBox from "../../../../src/components/TwitterBox/twitter-box";
import PostsNavigation from "../../../../src/components/Post/posts-navigation";
import heroImg from "../../../../src/images/posts/009/anchors-for-headings-in-mdx.svg";
import H2Example from "../../../../src/components/PostExtras/H2Example/h2-example";
import * as React from 'react';
export default {
  Date,
  HeroPost,
  TwitterBox,
  PostsNavigation,
  heroImg,
  H2Example,
  React
};