import Date from "../../../../src/components/Post/date";
import HeroPost from "../../../../src/components/Hero/hero-post";
import TwitterBox from "../../../../src/components/TwitterBox/twitter-box";
import PostsNavigation from "../../../../src/components/Post/posts-navigation";
import heroImg from "../../../../src/images/posts/006/search-tolerance.svg";
import styles from "../../../../src/pages/posts/006-search-with-typo-tolerance.module.css";
import SearchTolerance from "../../../../src/components/PostExtras/SearchTolerance/search-tolerance";
import * as React from 'react';
export default {
  Date,
  HeroPost,
  TwitterBox,
  PostsNavigation,
  heroImg,
  styles,
  SearchTolerance,
  React
};