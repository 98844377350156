import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button/button';
import Caption from '../../Caption/caption';

const ExportTitleChanger = () => {
  const [isHidden, setIsHidden] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [originalTitle, setOriginalTitle] = useState('');

  useEffect(() => {
    function visibilityChange() {
      setIsHidden(document.hidden);
    }

    document.addEventListener('visibilitychange', visibilityChange);

    return () =>
      document.removeEventListener('visibilitychange', visibilityChange);
  }, []);

  useEffect(() => {
    if (!originalTitle) {
      setOriginalTitle('Effective titles and more | Tomek Dev');
    }

    if (percentage > 0) {
      if (isHidden) {
        document.title = `${percentage}% Preparing video | Tomek Dev`;
      } else {
        document.title = originalTitle;
      }
      setIsRunning(true);

      let finalTimer;
      const timer = setTimeout(() => {
        if (percentage + 1 < 100) {
          const step = Math.floor(Math.random() * 3) + 1;
          const newValue = percentage + step > 100 ? 100 : percentage + step;
          setPercentage(newValue);
        } else {
          setPercentage(0);
          setIsRunning(false);
          document.title = `⚬ Your file is ready! | Tomek Dev`;

          finalTimer = setTimeout(() => {
            document.title = originalTitle;
          }, 4000);
        }
      }, 100);
      return () => {
        clearTimeout(timer);

        if (finalTimer) {
          clearTimeout(finalTimer);
        }
      };
    }
  }, [percentage, isHidden]);

  return (
    <div className="container ta-c">
      <Button
        type="button"
        disabled={isRunning}
        onClick={() => {
          setPercentage(1);
        }}
      >
        {isRunning ? 'Go to a different tab' : 'Fake "export" button'}
      </Button>

      <Caption>
        {isRunning ? `Progress: ${percentage}%` : '(Go to a different tab)'}
      </Caption>
    </div>
  );
};

ExportTitleChanger.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
};

export default ExportTitleChanger;
