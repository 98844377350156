import colorUtil from 'color-util';

function padNumber(n) {
  let r = n + '';

  if (parseInt(r) < 10) {
    r = `0${r}`;
  }

  if (parseInt(r) < 100) {
    r = `0${r}`;
  }

  return r;
}

export function blendRgbaWithWhite(rgba) {
  const color = colorUtil.color(rgba);
  const a = Math.round((100 * color.rgb.a) / 256) / 100;
  const r = Math.floor(color.rgb.r * a + 0xff * (1 - a));
  const g = Math.floor(color.rgb.g * a + 0xff * (1 - a));
  const b = Math.floor(color.rgb.b * a + 0xff * (1 - a));

  return '#' + ((r << 16) | (g << 8) | b).toString(16);
}

export function hexToRgb(hex) {
  const hexNoHash = hex.substring(1, hex.length);
  const r = parseInt(hexNoHash.substring(0, 2), 16);
  const g = parseInt(hexNoHash.substring(2, 4), 16);
  const b = parseInt(hexNoHash.substring(4, 6), 16);

  return [r, g, b];
}

export function hexToRgba(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result) {
    const r = padNumber(parseInt(result[1], 16));
    const g = padNumber(parseInt(result[2], 16));
    const b = padNumber(parseInt(result[3], 16));
    return `rgba(${r},${g},${b},1)`;
  } else {
    return null;
  }
}

export function intColorFromHex(hex) {
  const h = hex.replace('#', '');
  const r = parseInt(h.charAt(0) + h.charAt(1), 16);
  const g = parseInt(h.charAt(2) + h.charAt(3), 16);
  const b = parseInt(h.charAt(4) + h.charAt(5), 16);
  return r + g + b;
}

export function padRgba(rgba) {
  const value = rgba.replace('rgba(', '').replace(')', '').split(',');
  const r = padNumber(value[0]);
  const g = padNumber(value[1]);
  const b = padNumber(value[2]);
  return `rgba(${r},${g},${b},${value[3]})`;
}

export function rgbToHex(rgb) {
  return (
    '#' +
    ((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1)
  );
}
