import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../RadialGauge.module.css';

import MoonSolid from '../../../images/moon-solid.svg';
import SunSolid from '../../../images/sun-solid.svg';
import {
  calculateSetterPosition,
  calculateStepsCoords,
  findClosestStep,
} from '../../../utils/radial-gauge-setter-position';

const INITIAL_VALUE_PERCENTAGE = 0;
const TRACK_SIZE_DEGREES = 270;
const TRACK_WIDTH_PX = 7;
const SETTER_RADIUS_RATIO = 0.8; // radius of setter scales with `trackWidth` (multiplication)
const SETTER_STROKE_RATIO = 1 / 3; // width of setter's stroke scales with `trackWidth` (multiplication)
const VIEW_BOX_SIZE_PX = 100;

export default function RadialGaugeStep3Interaction({
  showBalls,
  showText,
  value,
}) {
  const [stepsCoords, setStepsCoords] = useState([]);
  const [currentStep, setCurrentStep] = useState(null);

  const trackSizeDegrees = TRACK_SIZE_DEGREES;
  const viewBox = `0 0 ${VIEW_BOX_SIZE_PX} ${VIEW_BOX_SIZE_PX}`;
  const setterRadius = TRACK_WIDTH_PX * SETTER_RADIUS_RATIO;
  const setterStrokeWidth = TRACK_WIDTH_PX * SETTER_STROKE_RATIO;
  const radius = VIEW_BOX_SIZE_PX / 2 - TRACK_WIDTH_PX / 2 - setterRadius;

  const circumference = 2 * Math.PI * radius;

  // Track
  const dasharray = circumference;
  const trackFillPercentage = trackSizeDegrees / 360;
  const trackDashoffset = circumference * (1 - trackFillPercentage);

  const cxy = VIEW_BOX_SIZE_PX * 0.5;
  const trackTransform = `rotate(${
    -(trackSizeDegrees / 2) - 90
  }, ${cxy}, ${cxy})`;

  // Value filler (color on track)
  const valuePercentage = (value / 100) * trackFillPercentage;
  const valueDashoffset = circumference * (1 - valuePercentage);

  // Setter
  const setterPos = calculateSetterPosition(radius, TRACK_SIZE_DEGREES, value);
  const setterTranslate = `translate(${setterPos.x}, ${setterPos.y})`;

  useEffect(() => {
    const stepsCoords = calculateStepsCoords(radius, TRACK_SIZE_DEGREES);
    setStepsCoords(stepsCoords);
  }, []);

  function onMove(event) {
    const isTouch = event.touches && event.touches[0];

    const cxy = event.currentTarget.clientWidth / 2;

    const pageX = isTouch ? event.touches[0].pageX : event.pageX;
    const pageY = isTouch ? event.touches[0].pageY : event.pageY;
    const x = pageX - event.currentTarget.offsetLeft - cxy;
    const y = pageY - event.currentTarget.offsetTop - cxy;

    const closestStep = findClosestStep(stepsCoords, x, y);

    if (currentStep?.value !== closestStep.value) {
      setCurrentStep(closestStep);
    }
  }

  return (
    <div className={styles.gaugeContainer} onMouseMove={(ev) => onMove(ev)}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
        <defs>
          <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#ffc400" />
            <stop offset="100%" stopColor="#856600" />
          </linearGradient>
        </defs>

        <circle
          fill="none"
          strokeLinecap="round"
          cx="50%"
          cy="50%"
          r={radius}
          stroke="#717276"
          strokeDasharray={dasharray}
          strokeDashoffset={trackDashoffset}
          strokeWidth={TRACK_WIDTH_PX}
          transform={trackTransform}
        />

        <circle
          fill="none"
          strokeLinecap="round"
          cx="50%"
          cy="50%"
          r={radius}
          stroke="url(#linear)"
          strokeDasharray={dasharray}
          strokeWidth={TRACK_WIDTH_PX}
          strokeDashoffset={valueDashoffset}
          transform={trackTransform}
        />

        <circle
          className={styles.gaugeSetter}
          fill="#ffc400"
          stroke="#fff"
          cx="50%"
          cy="50%"
          r={setterRadius}
          strokeWidth={setterStrokeWidth}
          transform={setterTranslate}
        />

        {showBalls &&
          stepsCoords.length > 0 &&
          stepsCoords.map((stepCoord) => (
            <circle
              key={stepCoord.value}
              fill="white"
              cx="50%"
              cy="50%"
              transform={`translate(${stepCoord.x}, ${stepCoord.y})`}
              r={stepCoord.value === currentStep?.value ? '1.5' : '0.5'}
            />
          ))}
      </svg>

      <div className={styles.icon} data-testid="icon">
        <img
          src={value === 0 ? MoonSolid : SunSolid}
          width="20"
          height="20"
          alt=""
          role="presentation"
        />
      </div>

      {showText && (
        <div className={styles.text}>
          <div className={styles.textValue} data-testid="text-value">
            {value}
            <small>%</small>
          </div>
          <div className={styles.textLabel} data-testid="label">
            Brightness
          </div>
        </div>
      )}
    </div>
  );
}

RadialGaugeStep3Interaction.defaultProps = {
  showBalls: false,
  showText: true,
  step: 1,
  value: INITIAL_VALUE_PERCENTAGE,
};

RadialGaugeStep3Interaction.propTypes = {
  showBalls: PropTypes.bool,
  showText: PropTypes.bool,
  step: PropTypes.number,
  value: PropTypes.number,
};
