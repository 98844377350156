import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './search-tolerance.module.css';
import Input from '../../Input/input';
import levenshtein from '../../../utils/levenshtein';

import apple from '../../../images/posts/006/apple.svg';
import banana from '../../../images/posts/006/banana.svg';
import blueberry from '../../../images/posts/006/blueberry.svg';
import cherries from '../../../images/posts/006/cherries.svg';
import lemon from '../../../images/posts/006/lemon.svg';
import pear from '../../../images/posts/006/pear.svg';
import pineapple from '../../../images/posts/006/pineapple.svg';
import tomato from '../../../images/posts/006/tomato.svg';

class SearchTolerance extends Component {
  constructor(props) {
    super(props);

    this.MIN_DISTANCE = 3;
    this.fruits = [
      { name: 'Apple', img: apple },
      { name: 'Banana', img: banana },
      { name: 'Blueberry', img: blueberry },
      { name: 'Cherries', img: cherries },
      { name: 'Lemon', img: lemon },
      { name: 'Pear', img: pear },
      { name: 'Pineapple', img: pineapple },
      { name: 'Tomato', img: tomato },
    ];

    this.state = {
      searchText: '',
      filteredFruits: this.fruits,
      levenshteinUsed: false,
    };

    this.search = this.search.bind(this);
  }

  search(event) {
    const { showDistance, sort, tolerance } = this.props;
    const value = event.target.value;
    const valueLC = value.toLowerCase();
    let levenshteinUsed = false;
    let filteredFruits;

    if (value) {
      filteredFruits = this.fruits.filter((fruit) => {
        return this._baseFiltering(fruit.name, valueLC);
      });

      if (filteredFruits.length === 0 && tolerance) {
        filteredFruits = this.fruits.filter((fruit) => {
          return this._levenshteinFiltering(fruit.name, valueLC);
        });

        if (filteredFruits.length > 0) {
          levenshteinUsed = true;
        }
      }

      if (showDistance || sort) {
        filteredFruits.forEach((fruit) => {
          fruit.distance = levenshtein(fruit.name.toLowerCase(), valueLC);
        });
      }

      if (sort) {
        filteredFruits.sort((a, b) => a.distance - b.distance);
      }
    } else {
      filteredFruits = this.fruits;
    }

    this.setState({
      searchText: value,
      filteredFruits,
      levenshteinUsed,
    });
  }

  _baseFiltering(fruitName, searchText) {
    return fruitName.toLowerCase().includes(searchText);
  }

  _levenshteinFiltering(fruitName, searchText) {
    const distance = levenshtein(fruitName.toLowerCase(), searchText);
    return distance <= this.MIN_DISTANCE;
  }

  _getLabel(label, searchText) {
    if (searchText) {
      return this._getLabelWithHighlights(label, searchText);
    } else {
      return label;
    }
  }

  _getLabelWithHighlights(label, searchText) {
    const regex = new RegExp(searchText, 'i');
    const newLabel = label.replace(
      regex,
      `<span class=${styles.highlight}>$&</span>`
    );

    return <span dangerouslySetInnerHTML={{ __html: newLabel }} />;
  }

  render() {
    const { showDistance } = this.props;
    const { filteredFruits, levenshteinUsed, searchText } = this.state;
    const hasResults = filteredFruits.length > 0;
    let list;

    if (hasResults) {
      list = filteredFruits.map((fruit, index) => {
        return (
          <li key={index}>
            <img src={fruit.img} alt={fruit.name} className={styles.itemImg} />
            <div>{this._getLabel(fruit.name, searchText)}</div>

            {searchText && showDistance && (
              <span className={styles.distance}>{fruit.distance}</span>
            )}
          </li>
        );
      });
    } else {
      list = <span>No results ¯\_(ツ)_/¯</span>;
    }

    return (
      <div className={styles.searchContainer}>
        <Input
          label="Search"
          type="search"
          name="search"
          autoComplete="off"
          onChange={this.search}
          value={searchText}
        />

        {levenshteinUsed && (
          <div className={styles.levenshteinOn}>
            We couldn't find it. Did you mean
            {filteredFruits.length > 1 && ' one of these'}?
          </div>
        )}

        <ul className={styles.searchList}>{list}</ul>
      </div>
    );
  }
}

SearchTolerance.defaultProps = {
  showDistance: false,
  sort: false,
  tolerance: false,
};

SearchTolerance.propTypes = {
  showDistance: PropTypes.bool,
  sort: PropTypes.bool,
  tolerance: PropTypes.bool,
};

export default SearchTolerance;
