/**
 * Prepares anchor (value for id) based on given text
 * @param {string|array} text
 * @returns {string}
 */
export default function (text) {
  let textValue = text;

  if (Array.isArray(text)) {
    textValue = text.filter((t) => typeof t === 'string').join('');
  }

  return textValue
    .toLowerCase()
    .replace(/[^a-z0-9 ]/g, '')
    .replace(/[ ]/g, '-');
}
