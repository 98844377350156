import React from 'react';

export default function StrokeDashSvgExample() {
  return (
    <svg viewBox="0 0 30 4" xmlns="http://www.w3.org/2000/svg">
      <line x1="0" y1="1" x2="30" y2="1" stroke="#2a2a2a" />
      <line x1="0" y1="3" x2="30" y2="3" stroke="#2a2a2a" strokeDasharray="4" />
    </svg>
  );
}
