import Date from "../../../../src/components/Post/date";
import PostsNavigation from "../../../../src/components/Post/posts-navigation";
import TwitterBox from "../../../../src/components/TwitterBox/twitter-box";
import HeroPost from "../../../../src/components/Hero/hero-post";
import heroImg from "../../../../src/images/posts/001/gatsby-link.svg";
import * as React from 'react';
export default {
  Date,
  PostsNavigation,
  TwitterBox,
  HeroPost,
  heroImg,
  React
};