import {
  blendRgbaWithWhite,
  hexToRgba,
  intColorFromHex,
  padRgba,
} from './color';
import colorUtil from 'color-util';

export function basicSorting(colors) {
  return [...colors].sort((a, b) => a.localeCompare(b));
}

export function basicSortingWithTheSameFormat(colors) {
  const mappedColors = colors.map((color) => {
    const isHex = color.includes('#');
    if (isHex) {
      return hexToRgba(color);
    } else {
      return padRgba(color);
    }
  });
  return mappedColors.sort();
}

export function noAlphaSorting(colors, map = 'original') {
  const mappedColors = colors.map((color) => {
    const isRgba = color.includes('rgba');
    if (isRgba) {
      return { original: color, converted: blendRgbaWithWhite(color) };
    } else {
      return { original: color, converted: color };
    }
  });
  mappedColors.sort((a, b) => a.converted.localeCompare(b.converted));
  return mappedColors.map((c) => c[map]);
}

export function noAlphaSortingOnInts(colors) {
  const mappedColors = colors.map((color) => {
    const isRgba = color.includes('rgba');
    if (isRgba) {
      let converted = blendRgbaWithWhite(color);
      converted = intColorFromHex(converted);
      return { original: color, converted };
    } else {
      return { original: color, converted: intColorFromHex(color) };
    }
  });
  mappedColors.sort((a, b) => {
    if (a.converted < b.converted) {
      return -1;
    } else if (a.converted > b.converted) {
      return 1;
    } else {
      return 0;
    }
  });
  return mappedColors.map((c) => c.original);
}

export function oneDimensionSorting(colors, dim, dir = 'asc') {
  const mappedColors = colors
    .map((color) => {
      const isRgba = color.includes('rgba');
      if (isRgba) {
        return blendRgbaWithWhite(color);
      } else {
        return color;
      }
    })
    .map(colorUtil.color);

  return mappedColors
    .sort((colorA, colorB) => {
      if (colorA.hsl[dim] < colorB.hsl[dim]) {
        return dir === 'asc' ? -1 : 1;
      } else if (colorA.hsl[dim] > colorB.hsl[dim]) {
        return dir === 'asc' ? 1 : -1;
      } else {
        return 0;
      }
    })
    .map((color) => color.csshsl);
}

function colorDistance(color1, color2) {
  const x =
    Math.pow(color1[0] - color2[0], 2) +
    Math.pow(color1[1] - color2[1], 2) +
    Math.pow(color1[2] - color2[2], 2);
  return Math.sqrt(x);
}

export function sortWithClusters(colorsToSort) {
  const clusters = [
    { name: 'red', leadColor: [255, 0, 0], colors: [] },
    { name: 'orange', leadColor: [255, 128, 0], colors: [] },
    { name: 'yellow', leadColor: [255, 255, 0], colors: [] },

    { name: 'chartreuse', leadColor: [128, 255, 0], colors: [] },
    { name: 'green', leadColor: [0, 255, 0], colors: [] },
    { name: 'spring green', leadColor: [0, 255, 128], colors: [] },

    { name: 'cyan', leadColor: [0, 255, 255], colors: [] },
    { name: 'azure', leadColor: [0, 127, 255], colors: [] },
    { name: 'blue', leadColor: [0, 0, 255], colors: [] },

    { name: 'violet', leadColor: [127, 0, 255], colors: [] },
    { name: 'magenta', leadColor: [255, 0, 255], colors: [] },
    { name: 'rose', leadColor: [255, 0, 128], colors: [] },

    { name: 'black', leadColor: [0, 0, 0], colors: [] },
    { name: 'grey', leadColor: [235, 235, 235], colors: [] },
    { name: 'white', leadColor: [255, 255, 255], colors: [] },
  ];

  const mappedColors = colorsToSort
    .map((color) => {
      const isRgba = color.includes('rgba');
      if (isRgba) {
        return blendRgbaWithWhite(color);
      } else {
        return color;
      }
    })
    .map(colorUtil.color);

  mappedColors.forEach((color) => {
    let minDistance;
    let minDistanceClusterIndex;

    clusters.forEach((cluster, clusterIndex) => {
      const colorRgbArr = [color.rgb.r, color.rgb.g, color.rgb.b];
      const distance = colorDistance(colorRgbArr, cluster.leadColor);

      if (typeof minDistance === 'undefined' || minDistance > distance) {
        minDistance = distance;
        minDistanceClusterIndex = clusterIndex;
      }
    });

    clusters[minDistanceClusterIndex].colors.push(color);
  });

  clusters.forEach((cluster) => {
    const dim = ['white', 'grey', 'black'].includes(cluster.name) ? 'l' : 's';
    cluster.colors = oneDimensionSorting(
      cluster.colors.map((color) => color.hex),
      dim
    ).map(colorUtil.color);
  });

  return clusters;
}
