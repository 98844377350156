import React from 'react';
import PropTypes from 'prop-types';
import styles from '../RadialGauge.module.css';

import MoonSolid from '../../../images/moon-solid.svg';
import SunSolid from '../../../images/sun-solid.svg';
import { calculateSetterPosition } from '../../../utils/radial-gauge-setter-position';

const INITIAL_VALUE_PERCENTAGE = 0;
const TRACK_SIZE_DEGREES = 270;
const TRACK_WIDTH_PX = 7;
const SETTER_RADIUS_RATIO = 0.8; // radius of setter scales with `trackWidth` (multiplication)
const SETTER_STROKE_RATIO = 1 / 3; // width of setter's stroke scales with `trackWidth` (multiplication)
const VIEW_BOX_SIZE_PX = 100;

export default function RadialGaugeStep2Drawing({ step, value }) {
  const trackSizeDegrees = TRACK_SIZE_DEGREES;
  const viewBox = `0 0 ${VIEW_BOX_SIZE_PX} ${VIEW_BOX_SIZE_PX}`;
  const setterRadius = TRACK_WIDTH_PX * SETTER_RADIUS_RATIO;
  const setterStrokeWidth = TRACK_WIDTH_PX * SETTER_STROKE_RATIO;
  let radius = VIEW_BOX_SIZE_PX / 2 - TRACK_WIDTH_PX / 2;

  if (step >= 4) {
    radius -= setterRadius;
  }

  const circumference = 2 * Math.PI * radius;

  // Track
  const dasharray = circumference;
  const trackFillPercentage = trackSizeDegrees / 360;
  const trackDashoffset = circumference * (1 - trackFillPercentage);

  const cxy = VIEW_BOX_SIZE_PX * 0.5;
  const trackTransform = `rotate(${
    -(trackSizeDegrees / 2) - 90
  }, ${cxy}, ${cxy})`;

  // Value filler (color on track)
  const valuePercentage = (value / 100) * trackFillPercentage;
  const valueDashoffset = circumference * (1 - valuePercentage);

  // Setter
  const setterPos = calculateSetterPosition(radius, TRACK_SIZE_DEGREES, value);
  const setterTranslate =
    step >= 5
      ? `translate(${setterPos.x}, ${setterPos.y})`
      : `translate(0, -${step >= 4 ? '41' : '46'})`;

  return (
    <div className={styles.gaugeContainer}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
        <defs>
          <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#ffc400" />
            <stop offset="100%" stopColor="#856600" />
          </linearGradient>
        </defs>

        <circle
          fill="none"
          strokeLinecap="round"
          cx="50%"
          cy="50%"
          r={radius}
          stroke={step >= 2 ? '#717276' : 'white'}
          strokeDasharray={dasharray}
          strokeDashoffset={trackDashoffset}
          strokeWidth={TRACK_WIDTH_PX}
          transform={trackTransform}
        />

        <circle
          fill="none"
          strokeLinecap="round"
          cx="50%"
          cy="50%"
          r={radius}
          stroke={step >= 2 ? 'url(#linear)' : 'gold'}
          strokeDasharray={dasharray}
          strokeWidth={TRACK_WIDTH_PX}
          strokeDashoffset={valueDashoffset}
          transform={trackTransform}
        />

        {step >= 3 && (
          <circle
            fill="#ffc400"
            stroke="#fff"
            cx="50%"
            cy="50%"
            r={setterRadius}
            strokeWidth={setterStrokeWidth}
            transform={setterTranslate}
          />
        )}
      </svg>

      {step >= 2 && (
        <>
          <div className={styles.icon} data-testid="icon">
            <img
              src={value === 0 ? MoonSolid : SunSolid}
              width="20"
              height="20"
              alt=""
              role="presentation"
            />
          </div>

          <div className={styles.text}>
            <div className={styles.textValue} data-testid="text-value">
              {value}
              <small>%</small>
            </div>
            <div className={styles.textLabel} data-testid="label">
              Brightness
            </div>
          </div>
        </>
      )}
    </div>
  );
}

RadialGaugeStep2Drawing.defaultProps = {
  step: 1,
  value: INITIAL_VALUE_PERCENTAGE,
};

RadialGaugeStep2Drawing.propTypes = {
  step: PropTypes.number,
  value: PropTypes.number,
};
