import React, { useEffect, useState } from 'react';

import styles from './music-title-changer.module.css';

const MusicTitleChanger = () => {
  const [currentInterval, setCurrentInterval] = useState(null);
  const [title, setTitle] = useState('');
  const [originalTitle, setOriginalTitle] = useState('');

  function _equalizerTitle() {
    const equalizer = ['▁', '▂', '▃', '▄', '▅', '▆', '▇', '█'];
    let output = '';
    for (let i = 0; i < 7; i++) {
      output += equalizer[Math.floor(Math.random() * equalizer.length)];
    }
    return output;
  }

  function _songTitle(songTitle, songTitleLength, t) {
    let i = t;
    if (i > songTitleLength) {
      i -= songTitleLength;
    }
    const p1 = songTitle.substring(i, i + 1);
    const p2 = songTitle.substring(i + 1, songTitleLength);
    const p3 = ' ';
    const p4 = songTitle.substring(0, i);
    return p1 + p2 + p3 + p4;
  }

  useEffect(() => {
    if (!originalTitle) {
      setOriginalTitle('Effective titles and more | Tomek Dev');
    }

    if (title) {
      if (currentInterval) {
        clearInterval(currentInterval);
      }

      let t, interval;
      const songTitle = 'Darude - Sandstorm';
      const songTitleLength = songTitle.length;

      switch (title) {
        case 'listener':
          document.title = '___d[-_-]b_ - DJ Tomek';

          t = 0;
          interval = setInterval(() => {
            if (t % 2 === 0) {
              document.title = '__d[-_-]b__ - DJ Tomek';
            } else if (t === 1 || t === 5 || t === 9) {
              document.title = '_d[-_-]b___ - DJ Tomek';
            } else if (t === 3 || t === 7 || t === 10) {
              document.title = '___d[-_-]b_ - DJ Tomek';
            }
            t++;

            if (t === 10) {
              clearInterval(interval);
            }
          }, 1000);
          setCurrentInterval(interval);
          break;
        case 'dancer':
          document.title = 'ᕕ(⌐■_■)ᕗ ♪♬ - Dance with me';

          t = 0;
          interval = setInterval(() => {
            if (t % 2 === 0) {
              document.title = 'ᕓ(■_■¬)ᕟ ♬♪ - Dance with me';
            } else {
              document.title = 'ᕕ(⌐■_■)ᕗ ♪♬ - Dance with me';
            }
            t++;

            if (t === 10) {
              clearInterval(interval);
            }
          }, 1000);
          setCurrentInterval(interval);
          break;
        case 'equalizer':
          document.title = '█▄▅▄▁▂▄ Darude - Sandstorm';

          t = 0;
          interval = setInterval(() => {
            document.title = `${_equalizerTitle()} Darude - Sandstorm`;
            t++;

            if (t === 10) {
              clearInterval(interval);
            }
          }, 1000);
          setCurrentInterval(interval);
          break;
        case 'song':
          document.title = songTitle;

          t = 0;
          interval = setInterval(() => {
            document.title = _songTitle(songTitle, songTitleLength, t);

            if (t === 2 * (songTitleLength + 1)) {
              clearInterval(interval);
              document.title = originalTitle;
            }
            t++;
          }, 150);
          setCurrentInterval(interval);
          break;
        default:
          document.title = title;
          break;
      }

      if (title !== originalTitle) {
        const timer = setTimeout(() => {
          setTitle(originalTitle);
        }, 10000);

        return () => clearTimeout(timer);
      }
    }
  }, [title]);

  return (
    <div className="container">
      <ul className={styles.list}>
        <li>
          <span
            role="button"
            onClick={() => {
              setTitle('listener');
            }}
          >
            Let's show a listener
          </span>
        </li>
        <li>
          <span
            role="button"
            onClick={() => {
              setTitle('dancer');
            }}
          >
            Or a dancer
          </span>
        </li>
        <li>
          <span
            role="button"
            onClick={() => {
              setTitle('equalizer');
            }}
          >
            Equalizer?
          </span>
        </li>
        <li>
          <span
            role="button"
            onClick={() => {
              setTitle('song');
            }}
          >
            Song title floating
          </span>
        </li>
      </ul>
    </div>
  );
};

export default MusicTitleChanger;
