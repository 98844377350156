import React from 'react';
import PropTypes from 'prop-types';

import styles from './alert.module.css';

const Alert = ({ children }) => (
  <div className="container">
    <div className={styles.alert}>
      <div className={styles.alertHeadline}>Note</div>
      <div className={styles.alertBody}>{children}</div>
    </div>
  </div>
);

Alert.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
};

export default Alert;
