import React from 'react';
import PropTypes from 'prop-types';

import styles from './color-badge.module.css';

const ColorBadge = ({ color, hasBorder }) => {
  const border = hasBorder ? styles.hasBorder : '';
  const classes = `${styles.badge} ${border}`;

  return (
    <span
      className={classes}
      style={{ backgroundColor: color }}
      title={color}
    ></span>
  );
};

ColorBadge.propTypes = {
  color: PropTypes.string,
  hasBorder: PropTypes.bool,
};

export default ColorBadge;
