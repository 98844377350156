import React, { useState } from 'react';
import styles from './sorting-colors-with-select.module.css';
import SortingColors from '../SortingColors/sorting-colors';

const options = [
  { name: 'hue-asc', label: 'Hue ➚' },
  { name: 'hue-desc', label: 'Hue ➘' },
  { name: 'saturation-asc', label: 'Saturation ➚' },
  { name: 'saturation-desc', label: 'Saturation ➘' },
  { name: 'lightness-asc', label: 'Lightness ➚' },
  { name: 'lightness-desc', label: 'Lightness ➘' },
];

const SortingColorsWithSelect = () => {
  const [sortingAlgo, setSortingAlgo] = useState('saturation-asc');
  const selectedOption = options.find((option) => option.name === sortingAlgo);

  return (
    <>
      <div className="container">
        <select
          className={styles.select}
          value={sortingAlgo}
          onChange={(event) => {
            setSortingAlgo(event.target.value);
          }}
        >
          {options.map((option, index) => (
            <option key={index} value={option.name}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      <SortingColors sortingAlgo={selectedOption.name} />
    </>
  );
};

export default SortingColorsWithSelect;
