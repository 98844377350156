import { Link } from 'gatsby';
import Date from "../../../../src/components/Post/date";
import Alert from "../../../../src/components/Alert/alert";
import HeroPost from "../../../../src/components/Hero/hero-post";
import ColorBadge from "../../../../src/components/ColorBadge/color-badge";
import TwitterBox from "../../../../src/components/TwitterBox/twitter-box";
import SortingColors from "../../../../src/components/PostExtras/SortingColors/sorting-colors";
import SortingColorsWithSelect from "../../../../src/components/PostExtras/SortingColorsWithSelect/sorting-colors-with-select";
import PostsNavigation from "../../../../src/components/Post/posts-navigation";
import styles from "../../../../src/pages/posts/010-sorting-colors-in-js.module.css";
import heroImg from "../../../../src/images/posts/010/sorting-colors-in-js.svg";
import colorAffectedByBg from "../../../../src/images/posts/010/color-affected-by-background.svg";
import clusterColors from "../../../../src/images/posts/010/cluster-colors.svg";
import rgbColorCube from "../../../../src/images/posts/010/RGB_color_cube.svg";
import * as React from 'react';
export default {
  Link,
  Date,
  Alert,
  HeroPost,
  ColorBadge,
  TwitterBox,
  SortingColors,
  SortingColorsWithSelect,
  PostsNavigation,
  styles,
  heroImg,
  colorAffectedByBg,
  clusterColors,
  rgbColorCube,
  React
};