import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import styles from './posts-navigation.module.css';

const PostsNavigation = ({ prevLink, prevTitle, nextLink, nextTitle }) => (
  <div className="container">
    <div className={styles.postsNavigation}>
      {prevLink && (
        <div className={styles.prevBox}>
          <div className={styles.boxHead}>Prev</div>
          <Link to={prevLink} className={styles.boxLink}>
            {prevTitle}
          </Link>
        </div>
      )}

      {nextLink && (
        <div className={styles.nextBox}>
          <div className={styles.boxHead}>Next</div>
          <Link to={nextLink} className={styles.boxLink}>
            {nextTitle}
          </Link>
        </div>
      )}
    </div>
  </div>
);

PostsNavigation.propTypes = {
  prevLink: PropTypes.string,
  prevTitle: PropTypes.string,
  nextLink: PropTypes.string,
  nextTitle: PropTypes.string,
};

export default PostsNavigation;
