import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './button.module.css';

class Button extends Component {
  constructor(props) {
    super(props);
    this.defaultOnClick = this.defaultOnClick.bind(this);
  }

  defaultOnClick() {}

  render() {
    const {
      children,
      className,
      disabled,
      onClick,
      type = 'button',
    } = this.props;
    const handleOnClick = onClick || this.defaultOnClick;

    return (
      <button
        type={type}
        disabled={disabled}
        className={`${styles.button} ${className}`}
        onClick={handleOnClick}
      >
        {children}
      </button>
    );
  }
}

Button.defaultProps = {
  type: 'button',
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string,
};

export default Button;
