import { Link } from 'gatsby';
import Date from "../../../../src/components/Post/date";
import HeroPost from "../../../../src/components/Hero/hero-post";
import TwitterBox from "../../../../src/components/TwitterBox/twitter-box";
import PostsNavigation from "../../../../src/components/Post/posts-navigation";
import heroImg from "../../../../src/images/posts/007/highlight-text.svg";
import HighlightText from "../../../../src/components/PostExtras/HighlightText/highlight-text";
import * as React from 'react';
export default {
  Link,
  Date,
  HeroPost,
  TwitterBox,
  PostsNavigation,
  heroImg,
  HighlightText,
  React
};