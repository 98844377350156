import Alert from "../../../../src/components/Alert/alert";
import Date from "../../../../src/components/Post/date";
import HeroPost from "../../../../src/components/Hero/hero-post";
import TwitterBox from "../../../../src/components/TwitterBox/twitter-box";
import PostsNavigation from "../../../../src/components/Post/posts-navigation";
import heroImg from "../../../../src/images/posts/004/effective-titles.svg";
import SimpleTitleChanger from "../../../../src/components/PostExtras/SimpleTitleChanger/simple-title-changer";
import ExportTitleChanger from "../../../../src/components/PostExtras/ExportTitleChanger/export-title-changer";
import MusicTitleChanger from "../../../../src/components/PostExtras/MusicTitleChanger/music-title-changer";
import * as React from 'react';
export default {
  Alert,
  Date,
  HeroPost,
  TwitterBox,
  PostsNavigation,
  heroImg,
  SimpleTitleChanger,
  ExportTitleChanger,
  MusicTitleChanger,
  React
};